import { GlobeAltIcon } from "@heroicons/react/outline"
import links from "./links"

export default {
  header: {
    phone: "+358 9 4270 4752",
    email: "info@vivecho.com",
  },
  hero: {
    notificationText1: "Töitä",
    notificationText2: "Katso mitä olemme tehneet aiemmin",
    title1of2: "Mobiilisovellukset, IoT, Web-sovellukset",
    title2of2: "ja muut digitaaliset pilviratkaisut.",
    content:
      "Vivecho on tehokas ja ketterä kumppani digitaalisten tuotteiden ja palveluiden luonnissa. Yrityksessämme toimii niin digitalisaation asiantuntijoita, kuin digitaalisten ja fyysisten kokonaisuuksien rajapintoihin erikoistuneita osaajia.",
  },
  featureSection: {
    title: "Yhteistyökumppanisi digitaalisissa ratkaisuissa",
    subTitle: "Me rakastamme koodaamista",
    content:
      "Mobiilisovellukset, IoT, Web-sovellukset ja muut digitaaliset pilviratkaisut.",
    features: [
      {
        name: "Mobiilikehitys",
        link: links.mobile,
        icon: GlobeAltIcon,
        description:
          "Viime vuosina mobiilikehitys on kehittynyt mobiililaitteiden käytön yleistyessä.",
      },
      {
        name: "Ohjelmistokehitys",
        link: links.software,
        icon: GlobeAltIcon,
        description:
          "Ohjelmistokehitys on kasvanut ja avannut yrityksille uudet markkinaraot.",
      },
      {
        name: "Kotisivut",
        link: links.website,
        icon: GlobeAltIcon,
        description:
          "Kotisivujen tärkeys on noussut huomattavasti siitä, mitä se on ollut.",
      },
      {
        name: "Web-kehitys",
        link: links.webDev,
        icon: GlobeAltIcon,
        description:
          "Web-kehitys on selainpohjaisia sovelluksia tuottavaa ohjelmistokehitystä.",
      },
      {
        name: "Esineiden internet, IoT",
        link: links.iot,
        icon: GlobeAltIcon,
        description:
          "Esineiden internet mahdollistaa fyysisen laitteesi kytkennän verkkopalveluihin.",
      },
      {
        name: "Käyttöliittymäsuunnittelu",
        link: links.uiDesign,
        icon: GlobeAltIcon,
        description:
          "Hyvä koodi ei pelasta palvelua, jonka käyttäjäkokemus ei tyydytä sen käyttäjää.",
      },
    ],
  },
}
