import { Link } from "gatsby"
import React from "react"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import Lead from "../components/Lead"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Title from "../components/Title"
import data from "../content"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title={data.featureSection.title}
        description="Vivecholta saat korkealaatuiset Mobiilisovellukset, IoT, Web-sovellukset ja muut digitaaliset pilviratkaisut. 100% tyytyväisyys takuulla."
        pageImage={metaImg}
        pageUrl={links.index}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">{data.hero.title1of2}</span>
              <span className="block text-indigo-400">
                {data.hero.title2of2}
              </span>
            </h1>
          </HeroTitle>
          <HeroLead>{data.hero.content}</HeroLead>
        </Hero>
      </section>

      <section id="features">
        <DotSection backgroundClassName="bg-white" dotPosition="bottom-left">
          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1">
              <SubTitle tag="h2">{data.featureSection.title}</SubTitle>
              <Title tag="h2">{data.featureSection.subTitle}</Title>
              <Lead tag="p">{data.featureSection.content}</Lead>
            </div>
            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              {data.featureSection.features.map(feature => (
                <div key={feature.name}>
                  <dt>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <Link
                      to={feature.link}
                      className="mt-5 text-lg leading-6 font-medium text-indigo-600 block"
                    >
                      {feature.name}
                    </Link>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {feature.description}
                    <Link className="text-indigo-600 block" to={feature.link}>
                      Tutustu...
                    </Link>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </DotSection>
      </section>
    </Layout>
  )
}
