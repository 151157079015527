import React from "react"
import BaseTextElement, { BaseTextElementProps } from "./BaseTextElement"
import { TypoTag } from "./TypoTag"

interface Props extends BaseTextElementProps {
  tag: TypoTag
  children: string | JSX.Element
}

export default ({
  tag,
  children,
  additionalClassNames,
}: Props): JSX.Element => (
  <BaseTextElement
    baseClassNames="mt-4 max-w-2xl text-xl text-gray-500"
    additionalClassNames={additionalClassNames}
    tag={tag}
  >
    {children}
  </BaseTextElement>
)
